
//*interactive-banner*/
.interactive-banner {
	.htmlcontent {
		line-height: 24px;
		color: $theme-color-default;				
	}
	&.style1 {
		h3 {
			font-size: 18px;
			text-transform: uppercase;
			margin-top: 30px;
			position: relative;
			padding-bottom: 30px;
			&:before {
				content: "";
				position: absolute;
				width: 70px;
				height: 3px;
				background: $theme-color;
				left: 0;
				bottom: 0;
			}
		}
		.htmlcontent {
			@media (min-width: $screen-sm-max) {
				width: 60%;
			}
			margin-top: -25px;
			margin-left: 90px;
		}
	}
	&.style2 {
		text-align: center; 
		.effect-v10:hover img {
			animation: initial;
		}
		.image {
			display: inline-block;
		}
		h2 {
			font-size: 36px;
			letter-spacing: -1px;			
			font-weight: 400;
			line-height: 45px;
		}
		.htmlcontent {
			margin-bottom: 30px;
		}
	}
	&.style3 {
		position: relative;
		.action-button {
			position: absolute;
			bottom: 40px;
			left: 0;
			right: 0;
			text-align: center;
			margin: auto;
			a {
				height: 56px;
				line-height: 56px;
				padding: 0 40px;
				@extend .btn-theme-white;
			}
		} 
	}
	&.style4 {
		position: relative;
		&:hover {
			&:before {
				@include opacity(1);
			}
			.content {
				@include opacity(1);
			}
		}
		.content {
			@include opacity(0);
			@include transition (all .35s ease 0s);
		}
		h3 {
			font-size: 14px;
			letter-spacing: 5px;
			text-transform: uppercase;
			font-weight: 600;
			color: $theme-color;
		}
		h2 {
			font-size: 48px;
			@media (max-width: 480px) {
				font-size: 25px;
				letter-spacing: 0;
			}
			color: #fff;
			letter-spacing: -3px;
			font-weight: 400;
		}
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: url(#{$image-theme-path}bg-category.png) no-repeat;
			@include opacity(0);
			@include transition (all .35s ease 0s);
		}
		header {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			text-align: center;
			margin-top: 40px;
		}
		.action-button {
			position: absolute;
			bottom: 40px;
			left: 0;
			right: 0;
			text-align: center;
			margin: auto;
		} 
	}
	&.style5 {
		text-align: center;
		h3 {
			font-size: 36px;
			line-height: 48px;
			color: #fff;
			font-weight: 400;
		}
		.htmlcontent {
			font-size: 18px;
			color: #fff;
		}
		a {
			margin-top: 50px;
			@extend .btn;
			@extend .btn-theme-white;
		}
	}
	&.style-video {
		.image {
			position: relative;
		}
		.popup-video {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    height: 100px;
		    line-height: 95px;
		    width: 100px;
		    margin-top: -50px;
		    margin-left: -50px;
		    padding-left: 5px;
		    text-align: center;
		    color: #fff;
		    border: 4px solid rgba(255,255,255,.4);		    
		    z-index: 3;
		    -webkit-transition: border-color .5s cubic-bezier(0,0,.44,1.18);
		    transition: border-color .5s cubic-bezier(0,0,.44,1.18);
		    @include border-radius(50%);
		    &:after {
			    content: "\f04b";
			    font-family: $font-icon;
			    display: inline-block;
			    font-size: 32px;
			    -webkit-transition: transform .5s cubic-bezier(0,0,.44,1.18);
			    -webkit-transition: -webkit-transform .5s cubic-bezier(0,0,.44,1.18);
			    transition: -webkit-transform .5s cubic-bezier(0,0,.44,1.18);
			    transition: transform .5s cubic-bezier(0,0,.44,1.18);
			    transition: transform .5s cubic-bezier(0,0,.44,1.18),-webkit-transform .5s cubic-bezier(0,0,.44,1.18);
			}
		}
		.heading-title {
			font-size: 18px;
			margin-bottom: 20px;
		}
	}
	&.style-light {
		.heading-title {
			color: #fff;
		}
		a:hover {
			color: #fff;
			border-bottom-color: #fff;
		}
	}
}
//*image*/
.pa_single_image {
	display: block;
	img {
		display: inline-block;
	}
}

.category-thumb {
	position: relative;
	.image, .image:hover {
		@extend .effect-v10;
	}
	.caption {
		position: absolute;
		top: 40%;
		text-align: center;
		left:0;
		right:0;
		color: $white;
		h4, h4 a {
			color: $white;
			text-transform: uppercase;
			font-size: 24px;
			font-weight: 700;
		}
	}
}
/**/
.pavo-widget-featuredbox{
	.content-icon {
		font-size: 50px;
		color: $theme-color-default;
	}
	.content-title {
		font-size: 13px;
		color: $theme-color-default;
		font-weight: 600;
		font-family:$font-family-base;
		margin-bottom:0;
	}
	.content-description {
		color: $gray-light;			
		line-height: 18px;
	}
	&.layout1  {
		text-align: center;	
	}
	&.layout2 {
		.content-title {
			margin-top: 0;
			text-transform: uppercase;
		}
		.content-icon {
			width: 20%;
			float: left;
			&.icon-radius {
				background:$white;
				border: 1px solid $border-color;
				line-height: 24px;
				@include size(45px, 45px);
				.fa { font-size: $font-size-base + 3;}
			}
		}
		.content-box-right {
			padding-top:5px;
			overflow: hidden;
			padding-left: 10px;
		}
		&:hover {
			.content-icon {
				border-color: $theme-color;
				.fa { color: $theme-color;}
			}
			.content-title {
				color: $theme-color;
			}
		}
	}
	&.layout3 {		
		.content-box-left {
			float: left;
			text-align: right;
			padding-right: 20px;
		}
		.content-icon {
			overflow: hidden;
		}
	}
}

/*pavo-widget-heading*/
.pavo-widget-heading {
	h2 {
		font-size: 36px;
		font-weight: 400;
		letter-spacing: -1px;
		margin: 0;
	}
	&.nostyle {
		position: relative;
		border-bottom: 1px solid $brand-primary;
		h3 {
			text-transform: uppercase;
			font-size: 14px; 
			letter-spacing: 2px;
			padding: 0 30px 10px;
			text-align: left;
			z-index:1;
			position: relative;
			margin:0 auto;
			width: auto;
			color: $white;
			background: $brand-primary;
		}
	}
	&.style-left {
		.content-heading {
			border-bottom: 2px solid $theme-color;
			margin:0;
			span {
				padding:7px 20px 3px;
				background:$theme-color;
				@include border-radius(3px 3px 0 0);
				color: #ffffff;
				line-height: normal;
				text-transform: uppercase;
				font-family: $headings-font-family;
				font-size: 16px;
			}
		}
	}

	&.organe{
		position: relative;
		&:after {
			position: absolute;
			content:"";
			bottom:-5px;
			height: 5px;
			width:100%;
			left:0;
			z-index:9;
			@if $tabproduct-bg != false {
				background-image: url('#{$tabproduct-bg}');
				background-repeat: repeat-x;
				background-position: left bottom;
			}	
		}	
		.content-heading {
			border-color:$theme-color;
		}
	}
	&.blue {
		.content-heading {
			border-bottom-color:#5e90af;
			span { background: #5e90af}
		}
	}

	&.cyan {
		.content-heading {
			border-bottom-color:#29b3ce;
			span { background: #29b3ce}
		}
	}
	&.green {
		.content-heading {
			border-bottom-color:#3bac53;
			span { background: #3bac53}
		}
	}

	&.red {
		.content-heading {
			border-bottom-color:#F39336;
			span { background: #F39336}
		}
	}

	&.style-center {
		text-align: center;
		padding: 50px 0 0;
	}

	&.style-light-center {
		text-align: center;
		h1,h2,h3,h4,h5 {
			color: #fff;
		}
	}
	&.style-light-left {
		h1,h2,h3,h4,h5 {
			//color: #fff;
		}
		h5.content-heading {
			text-transform: uppercase;
			position: relative;
			padding-bottom: 15px;
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				width: 70px;
				height: 3px;
				background: $theme-color;
			}
		}
	}
	.text-right & {
		h5.content-heading:before {
			right: 0;
		}
	}
}
/*pavo-widget-image-text*/
.pavo-widget-image-text {
	.content-title {
		font-size: 16px;
		text-transform: uppercase;
		margin: 0 0 10px;
	}
	.content-description {
		line-height: 24px;
	}
	.content-image {
		border: 0;
		&:before {
			pointer-events: none;
		}
	}
	&.layout-2  {
		.content-text-right {
			overflow: hidden;
			padding-right: 20px;
		}
	}
	&.layout-3  {
		.content-text-left {
			overflow: hidden;
			padding-left: 20px;
		}
	}
}

.pavo-widget-instagram  {
	a {
		position: relative;
		display: block;
		overflow: hidden;
		&:hover {
			img {
			    transform: scale(1.1);
    			transform-origin: 0 0;
    		}
    		.footer {
    			@include opacity(1);
    			pointer-events: visible;
    		}
		}
	}
	img {
		//transform: scale(1) translate(-50%, -50%);
    	transform-origin: 0 0;
    	@include transition(0.3s all ease);
	}
	.footer {
		position: absolute;
	    width: 100%;
	    z-index: 10;
	    height: 100%;
	    top: 0; 
	    background: rgba(0,0,0,0.5);
	    color: #fff;
	    left: 0;
	    display: flex;
	    align-items: center;
	    @include opacity(0);
	    @include transition(0.3s all ease);
	    pointer-events: none;
	}
	.inner {
		width: 100%;
		text-align: center;
		> div {
			display: inline-block;
			+ div {
				margin-left: 10px;
			}
		}
	}
}
.container + .pav-builder {
	margin-top: -60px;
}
.pavo-widget-gallery {
	.item-text {
		text-align: center;
		text-transform: uppercase;
		color: $theme-color-second;
		margin-top: 10px;
		font-weight:600;

	}
}

.pav-productcategory {
	position:relative;
	padding-top:40px;
	margin-top:-40px;
	overflow:hidden;
	@extend .col-nopadding;
	.row {background: $white;}
	.tab-content {
		position: relative;
		h4 { margin:0;}
		.tab-pane { 
			background: $white;
			&.swiper-container { 
				overflow: visible;
				.swiper-slide {
					padding:10px;
					border-right:1px solid #E9E9E9;
					.product-layout { 
						border:0;
						.product-thumb .product-meta {
							padding:15px 0 16px;
						}
					}
				}
				.swiper-pager {
					top: -22px !important;
					width: 80px;
					right:-15px;
					> div {
						sursor: pointer;
						background-color:$white;
						color: $theme-color-second;
						border: 1px solid $border-color;
						@include border-radius(3px);
						@include size(23px,23px);
						@include opacity(1);
					}
				}
			}
		}
		.banner-image {
			img {
				width: 100%;
			}
		}
	}
}

.pav-newsletter {
	.form-control { height: 36px;}
}

.pa_single_image {
	img {
		width: 100%;
	}
}