.mainmenu .navbar-collapse {
    padding:0;
}
.mainmenu .navbar {
    background: $mainmenu-bg;
    margin-bottom: 0;
    border: 0;
    .pa-row-inner {border:0 !important;}
    .badges {
        position: absolute;
        top: 0;
        right: 0;
        font-size: $font-size-base - 6;
        font-weight: normal !important;
        padding: 3px 8px;
        line-height: 10px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $white;
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
        
    } 
    .badges-danger .badges {
        background:$brand-danger;
        &:after {
            border-top: 6px solid $brand-danger;
        }
    }
    .badges-info .badges {
        background: $brand-info;
        &:after {
            border-top: 6px solid $brand-info;
        }
    }
    .badges-primary .badges {
        background:$brand-primary;
        &:after {
            border-top: 6px solid $brand-primary;
        }
    }
    .badges-success .badges {
        background: $brand-success;
        &:after {
            border-top: 6px solid $brand-success;
        }
    }
    .caret {
        @extend .hide;
    }
    .parent {   
        > a {
            &:after {
                content: "\f0d7";
                font-size: 14px;
                font-family: FontAwesome;
                margin-left: 5px;
                text-align: center;
                @include opacity(0);
            }
        }
        &.dropdown > a:after {
            position: absolute;
        }
        &:hover {
            >a:after {
                @include opacity(1);
            }
        }
    }
    h1,h2,h3,h4,h5,h6 { 
        &.menu-title a,  &.content-title {
            color: $mainmenu-widget-title-color;      
            letter-spacing: normal;
            border: 0;  
            line-height: 20px;  
            margin: $mainmenu-widget-title-margin; 
            font-size: $mainmenu-widget-title-font-size; 
        }    
        &.content-title {
            margin-top: 20px;
            text-transform: uppercase;
            text-align: center;
        }
        &.menu-title {
            margin-bottom: 20px;
            margin-top: 0;
        }         
    }  
    .pavo-widget-heading { 
        margin: 0 0 30px;
    } 
    .navbar-nav {
        float: left;
        width: 100%;
        text-align: left;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;            
        }
        > li {
            float: left;
            display: inline-block;
            > a {
                color: $mainmenu-link-color;
                font-size: $mainmenu-link-font-size;
                font-weight: $mainmenu-link-font-weight;
                font-family:$menugamenu-font-family;
                text-transform: $mainmenu-link-text-transform;
                padding:$mainmenu-link-padding;
                position: relative;
                border-left: 1px solid $megamenu-item-border;
                &:hover,&:focus,&:active {
                    color: $mainmenu-link-hover-color;     
                    &:before {
                        background: $megamenu-background-hover;
                        width: 100%;
                    }              
                }  
                &:before {
                    background-color: transparent;
                    content: "";
                    height: 3px;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    top: -1px;
                    @include transition(all 0.4s ease-out 0s);
                    width: 3px;
                }              
            }
            &:last-child {
                > a {
                    border-right: 1px solid $megamenu-item-border;
                }
            }
            .dropdown-menu {
                top: 150%;
                left: 0;
                display: block;
                visibility: hidden;
                height: auto;
                font-weight: 300;
                @include opacity(0);
                margin-left: 0!important;
                border: 0;
                min-width: $mainmenu-dropdown-minwidth;
                padding: $mainmenu-dropdown-padding;
                background-color: $mainmenu-dropdown-bg;
                border-radius: 0px;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.24);
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.24);
                -webkit-transition: all 0.2s linear 0s;
                transition: all 0.2s linear 0s;
                text-align: left;
                li {
                    display: block;
                    a {
                        color: $mainmenu-dropdown-link-color;
                        font-size: $mainmenu-dropdown-link-font-size;
                        font-weight: $mainmenu-dropdown-link-font-weight;
                        padding: 5px 0px; 
                        display: inline-block;
                        line-height: $mainmenu-dropdown-link-line-height;
                        &:hover,&:focus{
                            background-color: transparent;
                            color: $theme-color;  
                        }
                        @extend .effect-menu;
                    }
                }
                .see-all {
                    color: $mainmenu-dropdown-link-color;
                    font-size: $mainmenu-dropdown-link-font-size;
                    font-weight: $mainmenu-dropdown-link-font-weight;
                    padding: 0px; 
                    line-height: $mainmenu-dropdown-link-line-height;
                }
                .container {
                    width: 100% !important;
                }
            }
            &:hover {
                .dropdown-menu {
                    visibility: visible;
                    top: 100%;
                    @include opacity(1);
                    -webkit-transition: all 0.2s linear 0s;
                    transition: all 0.2s linear 0s;
                }
            }
            .dropdown-submenu {
                position: relative;
                .dropdown-menu {
                    top: 0;
                    left: 120%;
                    position: absolute;
                    display: block;
                    visibility: hidden;
                    @include opacity(0);
                    -webkit-transition: all 0.4s ease;
                    transition: all 0.4s ease;
                }
                &:hover {
                   > .dropdown-menu {
                        visibility: visible;
                        left: 100%;
                        @include opacity(1);
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                    }
                }
            }
            
        }
        > li {
            &.aligned-fullwidth {
                position: static;
                > .dropdown-menu  {
                    margin:0 !important;
                    width: 100% !important;
                }
                .dropdown-submenu {
                    .dropdown-menu {
                        margin-left: 0;
                    }
                }
            }
            &.aligned-right {
                .dropdown-menu  {                   
                    left: auto;
                    right: 0;
                }
                .dropdown-submenu {
                    .dropdown-menu {
                        margin-right: 100%;
                    }
                }
            }
            &.aligned-left {
                .dropdown-menu  {
                    left: 0;
                    right: auto;
                }
                .dropdown-submenu {
                    .dropdown-menu {
                        margin-left: 100%;
                    }
                }
            }
            &.aligned-center {
                .dropdown-menu  {
                    left: 50%;
                    transform: translate(-50%);
                }
                .dropdown-submenu {
                    .dropdown-menu {
                        margin-left: 100%;
                    }
                }
            }
        }
    }
}
.style-light .mainmenu .navbar .navbar-nav.megamenu > li > a {
    color: #fff;
    &:hover {
        color: $theme-color;
    }
}
.mainmenu {
    background: transparent;
}
.pav-verticalmenu {
    min-width: 100%;
    padding: 0;
}
.pav-verticalmenu .mainmenu .navbar {
    background: none;
    .collapse {
        padding:0;
        min-width: 100%;
    }
    .badges {
        right: auto;
        left: 30px;
        top: -5px;
    }
    .navbar-nav {
        width: 100%;
        > li {
            display: block;
            float: none;
            width: 100%;
            border-bottom:1px solid $border-color;
            position: relative;
            &:before {
                color: #AAB2BD;
                content: "\f009";
                display: inline-block;
                font-family: "FontAwesome";
                font-weight: normal;
                padding: 0 5px;
                font-size: 10px;
                position: absolute;
                top: 18%;
                left:10px;
            }
            > a { 
                padding: 13px 20px 13px 30px;
                text-transform: none;
                font-weight: 500; 
                text-align:left;
                color:#333;
                font-family: $font-size-base;
                border-color: $border-color !important;
                &:hover {
                    color: $theme-color;
                }
                &:before {
                    display:none;
                }
            }
            &:last-child {
                border-bottom:none;
            }
        }
        .dropdown-menu  {
            margin-left: 0!important;
            padding: 30px;
            width: 100%;
            visibility: hidden;
            display: block;
            top: 0;
            left: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: all 0.35s ease-out 0s;
            -o-transition: all 0.35s ease-out 0s;
            transition: all 0.35s ease-out 0s; 
            background: $white;
            li a:hover, li a:focus {
                color: $theme-color;
            }
        }
        .dropdown:hover  >  .dropdown-menu {
            top: 0px;
            display: block;
            visibility: visible;
            opacity: 1;
            filter: alpha(opacity=100);
            left: 100%;
        }
        .dropdown-submenu > .dropdown-menu {
          border-radius: 0 6px 6px 6px;
          left: 100%;
          margin-left: -6px;
          margin-top: -6px;
          top: 0;
        }
    }
}
.menu-categories {
    text-align: center;
    li.dropdown {
        > a:after {
            content: "\f0d7";
            font-size: 14px;
            font-family: FontAwesome;
            margin-left: 10px;
            text-align: center;
            opacity: 0;
            filter: alpha(opacity=0);
        }
        &:hover > a:after {
            opacity: 1;
            filter: alpha(opacity=1);
        }
    }
}


.pavo-widget-megamenu {
    .veritcal-menu-style {
        .megamenu-heading {
            background: $brand-info;
            border: 1px solid $border-color;
            border-bottom:0;
            h3 { padding:13px 15px; border:0;font-family: $headings-font-family;
                color: $white; font-size: 18px; font-weight: normal; text-align: left}
            .fa { color: $white; font-size: 14px;}
        }
        .pav-verticalmenu {
            border: 1px solid $border-color;
            border-top:0;
            background-color: #fff;
            @if $verticalmenu-bg-image != false {
                background-image: url('#{$verticalmenu-bg-image}');
                background-repeat: repeat-x;
            }
        }
    }
    .vertical-dropdown-showed-style {
        position: relative;
        padding: 16px 0; 
        background: $theme-color; 
        .pav-verticalmenu {
            position: absolute;
            top: 100%;
            z-index: 100;
        } 
    }
    .vertical-dropdown-style {
        padding: 16px 0;
        @extend .effect-dropdown-menu;
        &:hover {
            background: $theme-color;            
            .megamenu-heading h3 {
                color: #fff;
                border-color: transparent;
                .fa {
                    color: #fff;
                }
            }
        }    
    }  
    &.style-light{
        &:hover {
            .megamenu-heading h3 {
                border-color: transparent;
            }
        }
        .megamenu-heading h3 {
            color: #fff;
            border-color: #565B69;
        }
        .vertical-dropdown-showed-style {
            .megamenu-heading h3 {
                border-color: $theme-color;
                .fa {
                    color: #fff;
                }
            }
        }
    }   
    .megamenu-heading h3 {
        color: $theme-color-default;
        text-transform: uppercase;
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        padding: 10px 30px;        
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .fa {
            font-size: 18px;
            margin-right:10px;
            color: $theme-color;
        }
    } 
}