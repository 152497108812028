// Large desktops and laptops 1200px
@media (min-width: $screen-lg) {

}

// Landscape tablets and medium desktops
//@media (min-width: 992px) and (max-width: 1199px)
@media (min-width: $screen-md) and (max-width: $screen-md-max) {
	#pav-verticalmenu .vertical-titile {
		padding-right: 25px;
		padding-left: 25px;
	}
	.header-default {
		.pav-megamenu .navbar-nav > li.aligned-left .dropdown-menu {
			left: -30vw;
		}
	}
	#pavo-sticky-header .navbar-nav > li > a {
		padding-bottom: 0px;
	}
	#pavo-sticky-header .menu-categories {
		padding: 0 30px 30px;
	}
	#pavo-sticky-header .pav-mainmenu .navbar-nav.verticalmenu > li + li {
	    padding-left: 10px;
	}
	#pavo-sticky-header .pav-mainmenu .navbar-nav.verticalmenu > li > a {
		padding-left: 0;
	    padding-right: 0;
	    font-size: 13px;
	}
	.footer-blockbuilder ul.list li + li {
		margin-left: 0;
	}
	.pav-mainmenu .navbar-nav > li > a {
		padding-left: 20px;
		padding-right: 20px;
	}

	.pavo-widget-vertical-menu .vertical-title {
		padding-right: 15px;
		padding-left: 15px;
		.fa {
			margin-right: 5px;
		}
	}
}

@media (max-width: $screen-sm-max) {
	.formNewLestter  {
		&.default {

		}
		&.white-popup {
			.inner { padding: 30px;}
		}
	}
 	
 	.swiper-container-multirow {
		.product-thumb {
			margin-bottom: 65px;
		}
	}
	.interactive-banner.style3 .image {
		text-align: center;
	}
	.text-sm-left {
		text-align: left!important;
	}
	.product-group-buttons button {
		display: block;
		+ button {
			margin: 0;
			margin-top: 10px;
		}
	}

	.pav-mobile-nav {
		> .inner {
			border-bottom: 1px solid $border-color;
		}
		.inner + .inner { padding-bottom:10px;}
	}
	.banner-image {
		img { width: 100%;}
	}
	#product-product .nav > li { padding: 0 8px;}
}
// Portrait tablets and small desktops
//@media (min-width: 768px) and (max-width: 991px)
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {

}

// Landscape phones and portrait tablets
//@media (max-width: 767px)
@media (max-width: $screen-xs-max) {	
}

// Portrait phones and smaller
//@media (max-width: 480px)
@media (max-width: $screen-phone) {
    .mini-cart.minicart-style-default {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 320px) {

}
