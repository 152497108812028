// Button
// ========================================================================


// cart
$button-addtocart-bg:               transparent !default;
$button-addtocart-color:            $theme-color !default;
$button-addtocart-font-size:        12px !default;
$button-addtocart-padding:          0 !default;
$button-addtocart-text-transform:   uppercase !default;
$button-addtocart-bg-hover:         #fff !default;
$button-addtocart-font-weight:      400!default;
$button-addtocart-color-hover:      $theme-color-default !default;

// action
$button-action-bg:                  #3d3d3d !default;
$button-action-color:               #bbbbbb !default;
$button-action-border-color:        $button-action-bg !default;

$button-action-bg-hover:                  $theme-color !default;
$button-action-color-hover:               $white !default;
$button-action-border-color-hover:        $button-action-bg-hover !default;


// Label New & Sale
// ========================================================================
$label-border:                      46px solid darken($theme-color-second, 10%) !default;
$label-color: 						$theme-color !default;
$label-font-size:                   10px !default;
$label-font-style:                  italic !default;
$label-new-bg:                      #739493 !default;
$label-transform:                   uppercase !default;
$label-bg-color:                    $theme-color !default;
$label-sale-bg:						#5ca7d1 !default;

// product label
$product-label-border-color			: none!default;
$product-label-color 				: $white  !default;
$product-label-font-size  			: 12px !default;
$product-label-font-family			: $font-family-base !default;
$product-label-font-weight			: 600 !default;
$product-label-new-bg				: red !default;
$product-label-special-bg			: $theme-color !default;
$product-label-padding				: 5px 10px !default;
$product-label-line-height          : 33px;


// prodduct availability
$product-warning-display		 : 0 !default;
$product-warning-avariable-now-bg: red !default;
$product-warning-avariable-now-color: $white !default;

$product-warning-outofstock-bg : blue !default;
$product-warning-outofstock-color : $white !default;


//---------- Product Elements
$product-block-padding: 			25px 25px 10px !default;
$product-block-margin:              0px !default;
$product-font-family:               $font-family-base !default;
$product-name-color:                $theme-color-default !default;
$product-name-hover-color:          $link-hover-color !default;
$product-name-font-size:            14px !default;
$product-name-font-weight:			600 !default;
$product-name-padding:              0px !default;

$product-price-color:				#f05423 !default;
$product-price-font-family:			$headings-font-family !default;
$product-price-font-size:			14px !default;
$product-price-font-weight:         600 !default;

$product-price-old-color:			$gray-light !default;
$product-price-old-font-size:       14px  !default;
$product-price-old-font-weight:     600 !default;

$product-new-box-bg:                $theme-color-second !default;
$product-new-box-text-color:        #b28500 !default;
$product-sale-box-bg:               red !default;
$product-sale-box-text-color:       #ae4e42 !default;

// Product alert stock

$product-stock-font-size : 11px;
$product-instock-color   : 	$white !default;
$product-instock-bg  	 : 	$theme-color-second !default;

$product-outofstock-color   :   $white !default;
$product-outofstock-bg      : 	$theme-color-second !default;


// Product Block
// ========================================================================
$product-bg-color:                  $brand-primary !default;
$product-border-color : 			$border-color !default;
$product-border-color-hover : 		$theme-color !default;
$product-content-bg: 				$product-bg-color !default;
$product-content-padding:			18px !default;
$product-container-border:			1px solid $border-color !default;


$product-filter-bg:                 #fff !default;
$product-filter-active:             $brand-primary !default;
$product-filter-font-size:          $font-size-base - 2 !default;
$product-filter-color:              $brand-primary !default;
$product-filter-padding: 			10px !default;
$product-filter-border-color :		$border-color !default;

$product-container-bg:              $white !default;
$product-deals-link-color:          $black !default;
$product-deals-link-hover-color:    $theme-color !default;
$product-image-border:              0px !default;
$product-image-radius:              0px !default;

$product-rating-font-size: 12px !default;




// Product Info
// ========================================================================
$product-info-padding:              19px !default;
$product-info-margin:               20px !default;
$product-info-border:               1px solid $border-color !default;
$product-info-bg:                   $white !default;

$product-info-price-font-size: 		 30px !default;
$product-info-price-old-font-size:  22px !default;
