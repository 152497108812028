/*
 * Off Canvas
 * --------------------------------------------------
 */

$width : 300px !default; 
@media screen and (max-width: $screen-md-min) {
    .row-offcanvas-right {
        right: 0;
    }
    .row-offcanvas-left {
        left: 0;
    }
    .row-offcanvas-right 
    .sidebar-offcanvas {
        right: -$width;
    }
    .row-offcanvas-left 
    .sidebar-offcanvas {
        left: -$width;
    }
    .row-offcanvas-right.active {
        right: $width;
    }
    .row-offcanvas-left.active {
        left: $width;
    }
    
    .row-offcanvas.active {
        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background: rgba(black, 0.75);
            content: "";
            z-index: 200;
        }
    }
}

@media (max-width: $screen-sm-max) {  
    .sidebar-offcanvas {
        position: absolute;
        top: 0;
        width: $width;
        background:$offcanvas-menu-bg;
        height: 100%;
        z-index: 999;
    }
    .row-offcanvas {
        position: relative;
        @include transition(all 0.25s ease-out);
    }
    
   .sidebar-offcanvas  .navbar-nav > li {
        display: block !important; 
        float: none;
        clear: both;
        overflow: hidden;
    }

    .btn-show-offcanvas {
      font-size: 20px; 
      color: $theme-color-default; 
      &:hover {
        color: $theme-color;
      }
    }
    .sidebar-offcanvas{   
        .navbar-header { display: none;} 
        .navbar {
            background: transparent;
            border: 0;
        }        
        .offcanvas-heading{
            background: darken( $offcanvas-menu-bg, 10%);
            padding:10px 20px;
            > button {
                position:absolute;
                top: 10px;
                right: 10px;
            }
            .quick-search-form {
                margin-right: 40px;
            }
            position:relative;
        }
        .offcanvas-top { 
            background: darken( $offcanvas-menu-bg, 20%);
            padding:20px;
        }
        .navbar-nav {
            width: 100%;
            text-align: left;  
            margin: 0;
            border-bottom: 1px solid $border-color;      
        }
        .navbar-collapse { 
            display: block;
            padding: 0;
            border: 0;
        }
        .navbar-nav > li {
            width: 100%;  
            display:block;   
            &.dropdown {
                > .dropdown-menu {
                    border-top: solid 1px $border-color;
                }
            }
        }
        .navbar-nav > li.aligned-fullwidth {
            position: relative!important;
        }
        .nav > li > a {
            display: block;  
            border-top: solid 1px $border-color;
            color: $black!important;
        }
        .dropdown-menu {
            position: relative!important;
            display: none!important;
            top: auto;
            left: auto;
            opacity:1!important;
            visibility: visible!important;
            min-width: 100%;
            width: 100%;
            padding: 30px;
            box-shadow: none;
            border: 0;
            
        }
        .nav > li > a,  .dropdown > i {
            padding-top: 11px;
            padding-bottom: 20px;
            line-height: 20px
        }
        .dropdown > i { 
            display: block;
            width: 40px;
            position: absolute;
            right: 0;
            z-index: 99;
            text-align: center;
            line-height: 20px;
            background:$brand-primary;
            color: #FFF;
        }
        .active > .dropdown-menu  {
            display: block!important;
            visibility: visible!important;
            transition:none!important;
            opacity:1!important;
            top:0 !important;
        }
    }
}