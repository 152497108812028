@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,600i,700,900');
@import url('https://fonts.googleapis.com/css?family=Alegreya');

@font-face {
    font-family: 'museo300';
    src: url('#{$fonts-theme-path}museo300-regular-webfont.eot');
    src: url('#{$fonts-theme-path}museo300-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-theme-path}museo300-regular-webfont.woff') format('woff'),
         url('#{$fonts-theme-path}museo300-regular-webfont.ttf') format('truetype'),
         url('#{$fonts-theme-path}museo300-regular-webfont.svg#museo300') format('svg');
    font-weight: normal;
    font-style: normal;
}