.category-info {
  img {
    margin-bottom: 20px;
  }
}

.categories-description {
  padding: 20px; 
  margin-bottom:20px;
  background:$white;
  border-top-right-radius:3px;
  border-top-left-radius:3px;
  border:1px solid #ebeef2;
  h3 {
    border-bottom:1px solid #ebeef2;
    color: $gray !important;
    padding-bottom: 10px;
  }
}
#product-search {
  .search-feilds {
    background: $white;
    margin-bottom: 20px;
    padding: 20px;
  }
  .products-collection{
     > .row:first-child {
      background: $white;
      margin-bottom: 20px;
      padding: 20px;
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b { top: 0;} 
  .select2-container--default .select2-selection--single{
      @include border-radius(3px);
      background:#ebeef2;
  } 
}

.flashsale-content {
  .select2-container--default .select2-selection--single .select2-selection__arrow b { top: 0;} 
  > .row:first-child {
    background: $white;
    padding: 20px 0;
    .select2-container--default .select2-selection--single{
      @include border-radius(3px);
      background:#ebeef2;
    } 
  }
}
.products-collection, 
.flashsale-content {
  .list-view {
    padding: 10px; 
    background:$white;
    border-top-right-radius:3px;
    border-top-left-radius:3px;
    border-bottom:1px solid $border-color;
    .form-group {
        margin:5px 0 0;
        .input-group-addon {
          background: transparent;
          border: 0;
          font-weight: 500;
        } 
        .select2-container--default .select2-selection--single{
          @include border-radius(3px);
          background:#ebeef2;
        }   
        .select2-container--default .select2-selection--single .select2-selection__arrow b { top: 0;} 
      }
      .form-control {
        border: 0;
        font-weight: 500;
        font-size: 14px;
        background: transparent;
        @include box-shadow(none);
      }
  }
	.content-above {
		margin-bottom: 30px;
	}
	.btn-split-grid {
		height: 46px;
    line-height: 46px;
	} 
}

.button-link-compare {
    background: $theme-color-second;
    color: $white;
    padding: 5px 15px;
    @include border-radius(3px);
    @include button-effect (#fff, #fff, $theme-color, $theme-color-second); 
}

#product-category {
  h3 {
    @extend .panel-title;
    + .row ul {
      @extend .list-unstyled;
    }
  }
}
.btn-split-grid {
  > button {
    background: $white;
    border: 0;
    color: $text-color;
    width: 30px;
    height: 30px;
    line-height: 30px;
    @include border-radius(0);
    text-align:center;
    border: 1px solid $border-color;
    &.active, &:hover, &:focus {
      color: $theme-color;
    }
    &:last-child {
      border-right:1px solid $border-color;
    }
  }
}



.header-category {
  .btn-group {
  	margin-right: 30px;
  }
  .inner {
    @extend .display-flex;    
  }
  
}
.information-sitemap {
    ul li {
        line-height: 30px;
    }
}
.refine-search {
    .list-group {
        a.list-group-item {
            font-size: 12px;
            font-weight: bold;
            color: $headings-color;
            padding: 10px 30px;
            font-weight: bold;
            margin: 0;
            font-family: $headings-font-family;
            background-color: $white;
        }
        &:first-child{
           a.list-group-item{
                padding-top: 20px;
           } 
        }
        div.list-group-item {
            border:none;
            padding: 5px 30px; 
        }
    }
    label {
        font-weight: normal;
        display: block;
        color: #838c8c;
        input[type="checkbox"] {
            margin: 0 5px 0 0;
            vertical-align: -1px;
        }
    }

}
.display {
    margin-top: 5px;
    > button {
        padding: 0;
        border: 1px solid #e5e5e5;
        font-size: 14px;
        line-height:0;
        color: #dbdbdb;
        padding: 7px;
        &:hover, 
        &.active {
            color: #222222;
        }
    }
}
.product-categoris {
    overflow: hidden;
}
/*paging*/
.paging {
    border-top: 1px solid #e9e9e9;
    padding-top:20px;
    margin-top: 30px;
    width: 100%;
}
ul.pagination, ul.pavo-pagination {
  margin: 0;
  > .active > span {
	background: #f2f2f2;
	color: $theme-color;
	border: 0;
  }
  > li {
  	&:first-child {
      > a,
      > span {
        margin-left: 0;
        margin-right: 20px;
        @include border-left-radius(50%);
      }
    }
    &:last-child {
      > a,
      > span {      	
        @include border-right-radius(50%);
      }
    }
	> a, > span {
	  border: 0;          
	  line-height: 52px;
	  text-align: center;
	  padding: 0;
	  font-weight: 500;
	  display: inline-block;
	  @include border-radius(50%);
	  @include size(52px,52px);
	  &:hover, &:focus {
		background: #f2f2f2;
		color: $theme-color;
		border: 0;
	  }
	}
  } 
}
/* filter by brands */
.filter{
    padding: 20px 0;
    border-bottom: 1px solid #e9e9e9;
    color: $theme-color;
    font-size: 13px;
    font-family: $font-family-serif;
    text-transform: uppercase;
    .control-label {
        margin: 8px;
        font-weight: bold;
        color: $theme-color;
    }
    #compare-total{
        padding-top: 5px;
        display: block;     
        font-weight: bold;
        color: $theme-color;
    }
    .btn-link{
        color: $white;
    }
    .input-sm{
        width: 80%;
    }
}
/*product detail*/
.product-images {
  text-align: center;
  display: flex;
  
  .image-preview {
    text-align: center;
  }
  .preview-thumbs {
    overflow:hidden;
  }
  .image-zoom:hover img {
    border-color: $border-color;
  }
  img { 
    max-width:100%;
    max-height: 100%;
    border: 1px solid transparent;
    &.xactive {
      border-color: $border-color;
      @include box-shadow(none);
    }
  }
  .thumb-addition {
    height: 100px;
  }
    
  &.style-left {  	
    .image-preview {
      order: 2;
      margin-left: 30px;
    }
    .preview-thumbs {
      order: 1;
    }
  }
  &.style-right {
  	justify-content: space-between;
    .image-preview { order:1 }
    .preview-thumbs { order:2 }
  }

  &.style-top { 
    flex-flow: row wrap;
    .image-preview {
      margin-top: 20px;
      order: 2;
    }
    .preview-thumbs {
      order:1;
      display: flex;
      flex-flow: row wrap;
    } 
    .thumb-addition {
      margin-bottom: 10px;
    }
  }

  &.style-bottom { 
    flex-flow: row wrap;

    .image-preview {
      margin-bottom: 20px;
      order: 1;
    }
    .preview-thumbs {
      order:2;
      display: flex;
      flex-flow: row wrap;
    } 
  }

  &.style-one {
    .image-preview {
      display:none;
    }
    flex-direction: column;
    .thumb-addition {
      height: inherit;
    }  
  }

  &.style-two {
    flex-direction: column;
    .image-preview { 
      display: none;
    }
    .preview-thumbs {  
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      .thumb-addition {
        height: auto;
        &:first-child { 
          width: 100%;
        }
        width: 50%;
      }
    }
  }
  .thumb-addition {
    position:relative;
    &:hover .btn-gallery { 
      display: block;
    }
  }
  .btn-gallery {
    display: none;
    position:absolute; 
    height: 20px;
    text-align: center;
    width: 20px;
    line-height: 20px;
    background:$primary;
    color:#FFF;
    bottom:0px;
    left:30px;
    font-size: 7px;
  }
  
  &.style-left .swiper-container, &.style-right .swiper-container { 
    height: 340px;
  }
  &.style-top .swiper-container, &.style-bottom .swiper-container {
    width: 440px
  }

 }
/***
***** Product pages *****
**/
/*************/
#product-product {
  .product-content-detail {
    background: $white;
    padding: 15px;
    @include border-radius(4px);
    border:1px solid $border-color;
    display: block;
    margin-bottom:20px;
    overflow:hidden;
  }
  ul.product-prices {
    	overflow: hidden;
    	margin-bottom: 10px;
      li {
        display: inline-block;
        + li {
          margin-right: 10px;
        }
      }
      h2 {
        color: $theme-color;
        font-weight: 600;
        font-size: 18px;
        margin:0;
      }
      span {
        font-size: 18px;
        color: $theme-color-second;
      }
    }
    h1 {
        font-size: 24px;
        line-height: 40px;
        font-weight: 400;
        margin: 0;
        text-transform: capitalize;
    } 
    .brand a, .instock {
      color: $theme-color;
    }
       
    .thumbnail {
        position: relative;
        margin-bottom: 30px;
        border: none;
        img {
        	border: 1px solid transparent;
        }
        &:hover img {
        	border-color: $border-color;
        }
    }
    .thumbs-preview.default {
        .carousel-controls {
            width: 100%;
            top: 40%;
            right: 0;
            margin-top: -10px;
            opacity: 0;
            transition: all 0.5s ease 0s;
        }
        &:hover{
            .carousel-controls {
                opacity: 1;
                &.left {
                    left: -20px;
                }
                &.right {
                    right: -20px;
                }
            }
        }
        .carousel-control {
            &.left {
                left: 0px;
            }
            &.right {
                right: 0px;
            }
        }
        .item {
            margin: 5px;
            a{
                position: relative;
                &.active{
                    &:before{
                        border-bottom-color:$theme-color;
                    }
                    // img{
                    //     border-color: $theme-color;
                    // }
                }
            }
        }
    }
    .image-additional{
        .item {
            text-align: center;
            a > img{
                margin-bottom: 10px;
                border: 1px solid transparent;
                //width: 100%;
                &:hover{
                   // border: 1px solid #4bb048;
                }
            }
        }
    } 
    .tab-content {
        ul,ol {
            list-style: initial;
        }
    }
    .details-productv1{
        border-radius: 5px; 
    }
    .qty{
        margin: 13px 20px 0 0;
    }
    .details-productv1,.tab-group{
        background-color: $white;
    }
    
    .carousel-control{
        font-size: 40px;
    }
}

#product {
  .form-group {
    margin: 30px 0 0;
    &.product-center {
    	.btn-cart {
    		margin-top: 0;
    		display: inline-block;
    	}
    }
  }
   
  .control-label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    color: $theme-color-default;
  } 
  .btn-cart {   	
  	@extend .btn;
  	@extend .btn-theme-default;
  }
  .btn-wishlist,
  .btn-compare {
    @extend .btn;
    @include border-radius(3px);
    @include button-effect ($theme-color-second, #fff, $theme-color, transparent); 
    span {
      display:none;
    }
  }

  .product-group-buttons {
    button {
      display: block;
      width: 100%;
      border-bottom: 1px solid $border-color;
      text-transform: uppercase;
      padding: 10px 0;
      color: $theme-color-second;
      margin:0;
      &:hover {
        color: $theme-color;
      }
    }
  }
  
}
.product-group-buttons {
	clear: both;
	display: block;
	width: 100%;
	margin-bottom: 40px;
	button {
	  display: inline-block;
	  float: none;
	  width: auto;
	  @extend .btn-link;
	  + button {
		  margin-left: 20px;
		}  
	}  
}

#product-product {
    .tab-group{
      margin: 0 0 20px;
    }
    .nav-tabs {
        //border-color: $border-color;
        //border-top: 1px solid $border-color;
        border-bottom: 0;
        padding: 20px 0;
    }
    .nav > li {
        position: relative;
        padding: 0 30px;
        float: none;
        display: inline-block;
        &:first-child {
        	padding-left: 0;
        }
        > a {
            font-size: 16px; 
            font-weight: 500;
            padding: 0 0 10px;
            display: inline-block;
            border: 0; 
            margin: 0;                
            text-transform: uppercase;      
            color: $theme-color-second;
            position: relative;
            span {
            	font-size: 12px;
            	position: absolute;
            	left: 100%;
            	top: 0;
            }
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              height: 3px;
              background: $theme-color;
              @include transition (all .35s ease 0s);
            }
            &:hover {
              color: $theme-color-default;
              background: transparent;
              &:before {
                width: 100%;
              }
            }
        }
        &.active {
            > a {
                color: $theme-color-default;
                border: 0;
                &:before {
                  width: 100%;
                }
            }
        }
    }
    .product-info.accordion {
        a {
            &:before {
                content: "\f068";
                font-family: "FontAwesome";
                position: absolute;
                right: 20px;
                display: inline-block;
            }
            &.collapsed:before {
                content: "\f067";
            }
        }
        .panel-collapse {
            padding: 0 30px 30px;
        }
    }
    .deals-option {
        > li { 
            > a {
                font-size: 26px;
                font-weight: 400;
            }
        }
    }
}
.custom-quantity {
  border-top: 1px solid $border-color;
  padding-top:10px;
}
.product-btn-quantity{
  display: inline-flex;
  white-space: nowrap;
  vertical-align: top;
  .quantity-number {
      > #input-quantity{
      width: 100px;
      height: 34px;
      padding: 0;
      background: transparent;
      text-align: center;
      border: 1px solid $border-color;
      border-radius: 0;
    }  
  }

  .quantity-wrapper {
    vertical-align: top;
    > input[type="button"] {
      display: block;
      background: transparent;
      padding: 0 5px;
      min-width: 25px;
      border: 1px solid $border-color;
      border-left:0;
      height:17px;
      line-height: 14px;
      &:hover, &:focus {
        background: $theme-color;
        color: #fff;
        border-color: $theme-color;
      }
      &.plus { border-bottom:0 !important;}
    }
  }

}

#product-product {
  .pavo-sticky-in-parent.is_stuck{
    margin-top: 90px; 
  }
}
#p-detail-accordion {
  margin: 60px 0;
  .panel-collapse {
    margin-bottom: 30px;
  }
  .panel-title {
    display: block;
  }
}

.row + #p-review {
  padding: 60px 0;
  border-top: 1px solid $border-color;
  margin-left: -100vw;
  margin-right: -100vw;
  .row {
    @extend .container;
  }
}

.product-shawdow-head {
  padding: 20px 0; 
}
.product-summary-shadow {
	.summary-inner {
		background-color: #fff;
		-webkit-box-shadow: 0 0 5px rgba(0,0,0,.1);
		box-shadow: 0 0 5px rgba(0,0,0,.1);
		padding: 30px 35px 15px 35px;
		text-align: center;
		h1 {
			margin-top: 0;
		}
		.product-btn-quantity { 
			margin-right: 10px;
		}
		#product .btn-cart {
			display: inline-block;
			padding: 0 20px;
			margin-top: 0;
		}
	}
}

/**
 * Product compare: show this in iframe
 */
.page-product-compare{
  .topbar, .header-bottom, header, footer, .powered , #top, .breadcrumb, #pavo-sticky-header{
    display: none !important;
  }
} 
/**
 * pavquickview
  */
.page-extension-module-pavquickview-show {
  .site {
    display: block!important; 
  } 
  .topbar, .header-bottom, header, footer, .powered , #top, .breadcrumb, #pavo-sticky-header, .pav-mobile-nav .inner {
    display: none !important;
  }
  .product-quickview-detail {
    padding: 30px;
  }
  #product-product .tab-group {
    margin-bottom: 0;
  }
  
}
/**
 * Check out page
 */

 #checkout-checkout{   
 	 h1{
 	 	text-align: center;  
    	margin: 0 0 30px; 
 	 }
   #content {
    background: $white; 
    padding: 20px; 
   }
   .panel-group {
    background: #fff;
    padding: 30px;
   }
 }
#form-review {
	.review-author strong {
		color: $theme-color-default;
		font-size: 16px;
	}
	.review-date {
		color: $theme-color-default;
		font-size: 12px;
		margin-right: 20px;
	}
	.the-review {
		margin-bottom: 30px;
	}
	h2 {
		margin-top: 0;		
		text-transform: uppercase;
		font-size: 16px;
		color: $theme-color-default;
	}
	legend {
		font-size: 16px;
		border: 0;
		text-transform: uppercase;
		padding-top: 30px;
		margin-bottom: 0;
	}
	.buttons {
		margin-top: 30px;
	}
}
#review + h2 {
	margin-top: 60px;
}
.product-related{
	background: $white;
	position:relative;
  padding: 20px 0;
  h3 {
    position: relative;
    text-align: center;
      &:after {
        background: $border-color;
        content: "";
        width: 100%; 
        height: 1px;
        top: 50%;
        left:0;
        position: absolute;
      }
      span {
        background: $white;
        padding: 10px 30px;
        z-index:99;
        position: relative;
        text-transform:uppercase;
        font-size: $font-size-base + 4;

      }
  }
  .row {
    @extend .container;
  }
}
.review-form-width {
    margin: 10% auto;
    padding: 0 30px;
    max-width: 600px;
    position: relative;
    z-index: 999999;
    background-color: #fff;
    .alert {
        margin-top: 20px;
    }
}
div.required {
	.control-label {
		&:before {
		  color: #f00;
		  content: "*";
		  font-weight: bold; 
		}
	}
}
.thumbnails {
	overflow: auto;
	clear: both;
	list-style: none;
	padding: 0;
	> img {
		width: 100%;
	}
	.image-additional {
		float: left;
		+ .image-additional {  
			margin-left: 20px;
		}
	}
}
.thumbnail > img, .thumbnail a > img {
  margin-left: 0;
}
.image-additional {
	max-width: 100px;
	a {
		margin-bottom: 20px;
		padding: 0px;
		display: block;
		border: 1px solid #ddd;
	}
}
.product-quickview-detail {
    width: 100%!important;
}
.quickview-thumbnail,.quickview-thumbnails{
    .image-additional.slide.carousel.vertical {
      padding-top: 40px;
    }
    .carousel-controls-v1{
        .carousel-control{
            top: 0;
        }
    }
}

.owl-carousel-play {
    position: relative;
}
.owl-carousel {
    background: transparent!important;
    margin: 0!important;
    .owl-wrapper-outer {
        border: none;
        @include box-shadow(none);
        @include border-radius(0);
    }
}

/* --------------------------------------
 * INFO TABLE
 * -------------------------------------- */

.info-table-icon {
  color: $theme-color-second;
  font-size: 18px;
  @include transition (all 0.3s ease-in-out 0s);
  &:hover {
    color: $theme-color-default;
    transform: rotateY(70deg);
  }
}

.info-table-content {
  color: #666666;
}

.info-table-content strong {
  color: #898989;
  font-weight: normal;
}

.info-table-row:first-of-type > *:after {
  display: none;
}

.info-table {
  line-height: 1.95em;
  border: 1px solid $border-color;
  width: 100%;
}

.info-table td,
.info-table th {
  border-bottom: 1px solid $border-color;
  padding: 6px 23px;
  position: relative;
  vertical-align: middle;
}

.info-table td:after,
.info-table th:after {
  background-color: #fff;
  content: "";
  height: 1px;
  position: absolute;
  top: -1px;
  width: 2px;
}

.info-table th {
  border-right: 1px solid $border-color;
  font-weight: 400;
  text-align: center;
  width: 63px;
}

.info-table th:after {
  right: 0;
}

.info-table td {
  border-left-style: none;
}

.info-table td:after {
  left: 0;
}
input#input-captcha {
    margin-bottom: 20px;
}
/* contact page */
.google-map {
    margin-bottom: 60px;
}
#information-contact {
	h3 {
		@extend .panel-title;
	}
	.btn-link {
		margin-left: 20px;
	}
}


//style page 404

/* page Account */
div.buttons {
	margin-top: 30px;
}
label.radio-inline input[type="radio"] {
	position: relative;
}
.form-horizontal {
	legend {
    border-bottom: 1px solid $border-color;
    text-transform:none;
    padding-bottom: 5px;
    margin-bottom:10px;
    font-size: 20px;
		@extend .panel-title;
	}
	
}
#account-register {
	h1 {
		margin-top: 0;
    text-transform:uppercase;
	}	
	
}
#account-login {
	.well {
		border: 0;
		padding: 20px;
    background: $border-color;
		@include box-shadow(none);
	}
	h2 {
		@extend .panel-title;
	}
	.btn {
		margin-top: 30px;
	}
}
#common-success {
	h1 {
		margin-top: 0;
	}
}
#account-account {
	h2 {
		display: block;
		@extend .panel-title;
	}
	ul {
		margin-bottom: 30px;
	}
}

.page-extension-module-pavstorelocator {
  header + ul.breadcrumb {
    padding: 20px $grid-gutter-width/2;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    @extend .container;       
    &:after {
        display: block;
    }
  }
  .pav-store-locator {
    margin-bottom: 20px;
  }
  h1 {
    display: none;
  }
  .footer-blockbuilder {
    margin-top: 0;
  }
  .locations-inner {
    padding: 30px;
  }
  a[target="_blank"] {
    @extend .btn-link;
  }
  .location-address,
  .location-telephone,
  .location-email,
  .location-fax {
    font-size: 12px;
  }
  #information-information {
    margin-bottom: 60px;
    ul.breadcrumb {
      min-height: auto;
      padding: 30px 0;
      margin-bottom: 0;
      &:after {
        content: none;
      }
    }
    .store-locations .row {
      margin: 0;
    }
  }
}