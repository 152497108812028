//Global variables
$image-theme-path                : '../image/' !default;
$image-theme-skin                : '../image/' !default;
$fonts-theme-path                : '../fonts/' !default;

// standard colors 
$white		    : #FFF !default;
$black			: #000 !default;
$price-new		: #f05423 !default;
$nocolor		: transparent !default;

// color for default theme
$primary  : $brand-primary !default;
$success  : $brand-success !default; 
$warning  : $brand-warning !default;
$info  : $brand-info !default;
$danger : $brand-danger !default;

$body-color : $text-color !default;

$header-top : #353d41 !default;

$header-center: #fff !default;

$header-bottom: #fcfcfc !default;

$body-bg-image: false !default;
$header-bottom-bg: false !default;
$verticalmenu-bg-image: false !default;
$tabproduct-bg: false !default;
$brands-bg: false !default;
$footer-blockbuilder : false !default;

$theme-color    : $primary !default; // main color which will be used for all main block styles... 
$border-color	: #E9E9E9 !default;

$theme-color-default             : $link-color !default;
$theme-color-second  			 : #353d41 !default;
$theme-color-three               : #949595 !default;


// Theme Margin, Padding 
$theme-margin    : 30px !default;
$theme-padding   : 30px !default;

// Fonts 
$font-icon: "FontAwesome";




//Import component variables 
@import "vars/elements";
@import "vars/product";
@import "vars/layout";
@import "vars/modules";
